export const POINT_CLOUD = 'point-cloud';
export const MODEL_MANAGER = 'model-manager';
export const BUILD_ORDER = 'build-order';
export const ESRI = 'esri';
export const FORGE = 'forge';
export const FOURD = 'fourd';
export const LEAFLET = 'leaflet';
export const MKI_IMPACT = 'mki';
export const RELATICS = 'relatics';
export const GISVIEWER = 'gis-viewer';
export const STAKEHOLDERS = 'stakeholders';
export const STAKEHOLDER_ANALYSIS = 'stakeholder-analysis';
export const SWOT_SCENARIOS = 'swot-scenarios';
export const FIT_GAP = 'fit-gap';
export const REUSE_POTENTIAL_INDEX = 'reuse-potential-index';
export const DHME_PROJECT_GANTT = 'project-gantt';
export const DHME_PROJECTS_DASHBOARD = 'projects-dashboard';
// DHME
export const DHME_MODULE_DASHBOARD = 'dhme-module-dashboard';
export const DHME_PROJECT_ASSEMBLY_PLANNING = 'dhme-project-assembly-planning';
export const DHME_WVB = 'dhme-wvb';
export const DHME_PROJECT_PHASES_PLANNING = 'dhme-assembly';
export const DHME_MODULE_ASSEMBLY = 'dhme-module-assembly';
export const DHME_DATA_VALIDATION = 'dhme-data-validation';
export const DHME_BILL_OF_MATERIAL = 'dhme-bom';
export const DHME_QUALITY_CONTROL = 'dhme-wkb';
export const DHME_WKB_INSPECTION_PLAN = 'dhme-wkb-inspection-plan';
export const DHME_WKB_LIBRARY = 'dhme-wkb-library';
export const DHME_WKB_RISK_ANALYSIS = 'dhme-wkb-risk-analysis';
export const DHME_M_ASSEMBLY_PLANNING = 'dhme-assembly-planning';
export const DHME_M_MODULE_DASHBOARD = 'dhme-master-module-dashboard';
export const DHME_M_TRESTLE_CHECKER = 'dhme-trestle-checker';
export const DHME_M_STATION_DASHBOARD = 'dhme-station-dashboard';
export const DHME_MODEL_IMPORT = 'dhme-model-import';
export const DHME_ASSEMBLY_PREPARATIONS = 'dhme-assembly-preparations';
export const DHME_M_ASSEMBLY_HALL_DASHBOARD = 'dhme-assembly-hall-dashboard';
export const DHME_M_PICK_ORDER_MANAGEMENT = 'dhme-pick-order-management';
export const DHME_M_YARD_MANAGEMENT = 'dhme-yard-management';
export const DHME_MANUAL_IMPORT = 'dhme-manual-import';
export const DHME_QR2FORM = 'dhme-qr-2-form';
export const DHME_ELEMENT_VALIDATION = 'dhme-element-validation';
export const DHME_PRODUCTION_PLANNING = 'dhme-production-planning';
export const DHME_PROJECT_INTAKE = 'dhme-project-intake';
export const DHME_HALL_CONFIGURATOR = 'dhme-hall-configurator';
export const DHME_PHASE_TRANSFER = 'dhme-phase-transfer';
export const DHME_DRAWING_BOARD = 'dhme-drawing-board';
export class DHME_CUSTOM {}
