import LocalStorageService from '@/services/local-storage';
import { changePassword, getUser, login } from '@/services/api/user.api';
import Vuetify from '@/plugins/vuetify';
import {
  requestPasswordReset,
  resetPassword,
  updateUser,
} from '@/services/api/users.api';
import { waitForVuex } from '@/utils/vuex';
import i18n from '@/lang/i18n';

const state = {
  token: LocalStorageService.getToken() || '',
  user: undefined,
  selectedLicense: undefined,
  authenticatedStatus: '',
  accessUser: null,
};
const mutations = {
  auth_request(state) {
    state.authenticatedStatus = 'loading';
  },
  auth_success(state, token) {
    state.token = token;
    state.authenticatedStatus = 'success';
  },
  auth_user_success(state, user) {
    state.user = user;
    state.authenticatedStatus = 'success';
  },
  set_license(state, license) {
    if (license) {
      state.selectedLicense = license;
      Vuetify.framework.theme.themes.light.primary = license.theme;
      window.sessionStorage.setItem('license', license.id);
    }
  },
  enable_two_factor(state) {
    state.user.two_factor_enabled = true;
  },
  disable_two_factor(state) {
    state.user.two_factor_enabled = false;
  },
  enable_account_access(state, user) {
    state.accessUser = user;
  },
  disable_account_access(state) {
    state.accessUser = null;
  },
};
const actions = {
  authenticate({ commit, dispatch }, user) {
    return new Promise((resolve, reject) => {
      commit('auth_request');
      login(user)
        .then((response) => {
          commit('auth_success', response.token);
          resolve(response);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content:
              error?.data?.message ||
              error?.message ||
              'Client error on login flow',
            color: 'error',
          });
          reject(error);
        });
    });
  },
  fetchAuthenticatedUser({ commit }) {
    if (LocalStorageService.getToken()) {
      return new Promise((resolve, reject) => {
        getUser()
          .then((user) => {
            if (user.language) {
              i18n.locale = user.language;
            }
            commit('auth_user_success', user);
            resolve(user);
          })
          .catch((error) => {
            const {
              status,
              data: { message },
            } = error;

            this.commit('showNotification', {
              content: [401].includes(status)
                ? 'Session expired. Relogin please.'
                : message,
              color: 'error',
            });

            LocalStorageService.removeToken();
            reject(error);
          });
      });
    }
  },
  updateAuthenticatedUser: waitForVuex(
    'user.update',
    async ({ commit, state }, image) => {
      state.user.photo = image || null;
      try {
        const user = await updateUser(state.user.id, state.user);
        if (user.language) {
          i18n.locale = user.language;
        }
        commit('auth_user_success', user);
        commit('showNotification', {
          content: `Updated user ${user.name}`,
          color: 'success',
        });
      } catch {
        commit('showNotification', {
          content: 'Failed to Updated user',
          color: 'error',
        });
      }
    }
  ),
  changePassword({ commit, state }, data) {
    data.email = state.user.email;
    return changePassword(data)
      .then((user) => {
        this.commit('showNotification', {
          content: `Successfully updated ${state.user.name}'s password`,
          color: 'success',
        });
      })
      .catch(() => {
        this.commit('showNotification', {
          content: 'Failed to change password',
          color: 'error',
        });
      });
  },

  requestPasswordReset({ commit }, email) {
    return requestPasswordReset(email);
  },
  resetPassword({ commit }, email, token, password, password_confirmation) {
    return resetPassword(email, token, password, password_confirmation);
  },
};
const getters = {
  isLoggedIn: (state) => !!state.token,
  authenticatedUser: (state) => state.user,

  isSystemAdmin: (state) => {
    if (state.user !== undefined) {
      return state.user.is_systemadmin;
    } else {
      return false;
    }
  },
  isProjectAdmin: (state, getters) => {
    if (state.user !== undefined) {
      if (state.user.is_systemadmin) {
        return true;
      } else {
        return (
          state.user?.roles?.filter(
            (team) =>
              team.is_projectadmin && team.project === getters.project?.id
          ).length > 0
        );
      }
    } else {
      return false;
    }
  },
  isUserAdmin: (state, getters) => {
    if (!state.user) {
      return false;
    }
    if (state.user.is_systemadmin) {
      return true;
    }

    return state.user?.licenses.find(
      (license) => license.id === state.selectedLicense?.id
    )?.is_admin;
  },
  selectedLicense: (state) => state.selectedLicense,
  authenticatedStatus: (state) => state.authenticatedStatus,
  accessedUser: (state) => state.accessUser,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
